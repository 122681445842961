import React from "react"
import styled from "styled-components"
import { CategoryHeader } from "../common/CategoryHeader"
import Select from "react-select"
import Clock from "../../images/OtherAssets/clock.svg";
import {isMobile} from 'react-device-detect';
import {Slide} from "react-reveal";
import {ErrorMessageText, HeaderAndQuestionMarkWrapper} from "../sharedComponents/SharedComponents";
import CircleWithQuestionMark from "../tooltip/CircleWithQuestionMark";

const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //z-index: 2;
  z-index: 10;
  cursor: pointer;
  margin-top: ${({ isNarrow, isFirst }) => (isNarrow || isFirst) ? "0" : "50px"};
  position: ${({isFirstOnPage}) => isFirstOnPage ? "unset" : "relative"};
  @media(min-width: 414px) {
	width: 353px;
  }
  @media(min-width: 536px) {
  	width: ${({ isNarrow }) => isNarrow ? "230px" : "476px"};
  }
  @media(min-width: 675px) {
	width: ${({ isNarrow }) => isNarrow ? "353px" : "476px"};
  }
  @media(min-width: 768px) {
  	width: 520px;
  }	
  @media(min-width: 1280px) {
  	width: ${({ isNarrow }) => isNarrow ? "340px" : "520px"};
  	max-width: 550px;
  }
  @media(min-width: 1507px) {
  	width: 520px;
  }
  @media(min-width: 1920px) {
  	width: ${({ isNarrow }) => isNarrow ? "280px" : "550px"};
  }
  ::-webkit-scrollbar {
	  width: 0px;
	  height: 0px;
}

  textarea:focus, input:focus {
    outline: none;
  }
  & > div {
    width: 100%;
  }
`

const colorStyles = {
	control: (styles, {isDisabled}) => ({
		...styles,
		cursor: "pointer",
		backgroundColor: isDisabled? "#F5F7F9" : "#FFFFFF",
		borderRadius: "10px",
		border: isDisabled? "1px solid white" : "1px solid #BFDEFE",
		boxShadow: "none",
		height: "50px",
		zIndex: "10",
		"&:hover": {
			borderColor: "#436C98"
		},
		"@media(min-width: 768px)" : {
			height: "60px",
		},
		"@media(min-width: 1920px)" : {
			minHeight: "60px",
		},
		"&>div": {
			overflow: "visible",
			justifyContent: "center"
		}
	}),
	placeholder: styles => ({
		...styles,
		color: "#959595",
		fontSize: "16px",
		fontWeight: "400",
		fontFamily: "'Source Sans Pro', sans-serif",
		left: "20px",
		top: "calc(50% - 10px)",
		transform: "unset",
	}),
	indicatorSeparator: styles => ({
		...styles,
		display: "none"
	}),
	dropdownIndicator: (base, state) => ({
		...base,
		transition: 'all .2s ease',
		transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
	}),
	indicatorsContainer: styles => ({
		...styles,
		cursor: "pointer",
		"svg": {
			fill: "#FC5757",
			width: "29px",
			height: "27px",
		},
		"@media(min-width: 768px)": {
			"svg": {
				width: "35px",
				height: "33px"
			}
		}
	}),
	menu: styles => ({
		...styles,
		backgroundColor: "transparent",
		boxShadow: "none"
	}),
	menuList: styles => ({
		...styles,
		backgroundColor: "white",
		borderRadius: "10px",
		border: "1px solid #BFDEFF",
		"::-webkit-scrollbar": {
			display: "none",
		}
	}),
	option: (styles, state) => ({
		...styles,
		fontFamily: "'Source Sans Pro', sans-serif",
		fontSize: "16px",
		lineHeight: "20px",
		fontWeight: "600",
		color: state.isSelected || state.isFocused ? "#436C98" : "#436C98",
		backgroundColor: state.isSelected || state.isFocused ? "#FAEFEF" : "transparent !important",
		textAlign: "left",
		cursor: "pointer",
		paddingLeft: state.isSelected || state.isFocused ? "24px" : "25px",
		boxSizing: "border-box",
		borderWidth: state.isSelected || state.isFocused ? "0px 1px 0px 1px" : "none",
		borderStyle: state.isSelected || state.isFocused ? "solid" : "none",
		borderColor: state.isSelected || state.isFocused ? "#FC5757" : "none",
	}),
	singleValue: styles => ({
		...styles,
		color: "#436C98",
		fontSize: "16px;",
		fontWeight: "600",
		fontFamily: "'Source Sans Pro', sans-serif",
		left: "25px",
		marginTop: "0",
		"@media(min-width: 1920px)" : {
			marginTop: "0",
		}
	}),
	noOptionsMessage: styles => ({
		...styles,
		color: "#fff"
	})
}

const ImageWhiteBackground = styled.div`
	background-color: white;
	width: 22px !important;
	height: 22px;
	z-index: 1000;
	position: absolute;
	right: 16px;
	top: calc(50% + 12px);
	@media(min-width: 372px){
		top: calc(50% + 6px);
	}
	@media(min-width: 402px) {
		top: calc(50% + 2px);
	}
`

const ClockIcon = styled.img`
	width: 22px;
	position: relative;
`

const ErrorValidation = styled.p`
	font-size: 16px;
	display:none;
	color: ${({theme}) => theme.colors.formAlwisOrange};
	position: absolute;
	transform: translateY(0px);
	text-align: center;
&:nth-of-type(1){
	display:block;
}`


const ErrorNone = styled.span`
visibility: hidden;
position:absolute;
`;

const CustomSelectReduxForm = ({input, onChange, value, name, id, type, meta: {touched, error}, selectLabel, placeholder, isNarrow, options, isLoading, selectedValue, isFirst, isClockShown, defaultValue, onClick, showCircle, isWindowVisible, tooltipHeader, tooltipDescription, isFirstOnPage }) => {
	return (
		<Slide top duration="300">
			<>
			<SelectWrapper isFirst={isFirst} isNarrow={isNarrow} isFirstOnPage={isFirstOnPage}>
				{!isNarrow && <HeaderAndQuestionMarkWrapper>
					<CategoryHeader>{selectLabel}</CategoryHeader>
					<CircleWithQuestionMark
						showCircle={showCircle}
						isFirstOnPage={isFirstOnPage}
						onClick={onClick}
						isWindowVisible={isWindowVisible}
						tooltipHeader={tooltipHeader}
						tooltipDescription={tooltipDescription}/>
				</HeaderAndQuestionMarkWrapper>}
				<Select {...input}
				        onChange={value => input.onChange(value)}
						// onBlur={() => input.onBlur(input.value)}
						onBlur={event => event.preventDefault()}
				        options={options}
				        styles={colorStyles}
				        placeholder={placeholder}
				        // value={input.value}
					// value={selectedValue}
					//     defaultValue={defaultValue}
					    isClockShown={isClockShown}
					    isDisabled={!options || !options.length}
					    isLoading={isLoading}
					    isSearchable={!isMobile}
					    menuPortalTarget={(typeof document !== `undefined`) ? document.querySelector("body") : null}
				/>
				{isClockShown &&  <ImageWhiteBackground>
					<ClockIcon src={Clock}/>
				</ImageWhiteBackground> }
			</SelectWrapper>
			{touched &&((error && <ErrorValidation> <ErrorNone> {error}</ErrorNone>pole wymagane</ErrorValidation>))}
		</>
		</Slide>
	)
}

export default CustomSelectReduxForm
